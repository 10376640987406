import React from "react"
import Layout from "../components/layout"
import { SectionTitle } from "../components/SectionTitle"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { StickyImage } from "../components/stickyImage"
import { Body, MainComponent, SideImg, TBListComponent, TBWBody } from "../components/about"

const About = () => {
    const pageData = useStaticQuery(graphql`
        query {
            contentfulSectionAbout {
                title
            }
            contentfulSectionAboutBodyRichTextNode {
                json
            }
            contentfulFooterLogo {
                logo {
                    file {
                        url
                    }
                }
            }
            contentfulTeachingBoostsHistory {
                title
            }
            contentfulTeachingBoostsHistoryBodyRichTextNode {
                json
            }
        }
    `)

    return (
        <Layout>
            <StickyImage />
            <MainComponent>
                <SectionTitle title={pageData.contentfulSectionAbout.title} />
                <Body>
                    <SideImg src={pageData.contentfulFooterLogo.logo.file.url} alt="side image" />
                    <div>{documentToReactComponents(pageData.contentfulSectionAboutBodyRichTextNode.json)}</div>
                </Body>
            </MainComponent>
            <TBListComponent>
                <h5>{pageData.contentfulTeachingBoostsHistory.title}</h5>
                <TBWBody>{documentToReactComponents(pageData.contentfulTeachingBoostsHistoryBodyRichTextNode.json)}</TBWBody>
            </TBListComponent>
        </Layout>
    )
}

export default About
